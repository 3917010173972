import React, { FC, useCallback } from 'react';
import { graphql, Link } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage';
import ProductLink from 'components/ProductLink';
import DangerouslySetInnerHTML from 'components/common/DangerouslySetInnerHtml';
import ProductCardTitle from 'components/ProductCard/ProductCardTitle';
import CardLabel from 'components/common/CardLabel';
import { gtmService } from 'services/gtmService';

import { ProductCardProps } from './model';

import './ProductCard.scss';

const ProductCard: FC<ProductCardProps> = ({
  label,
  labelBgColor,
  title,
  text,
  img,
  productRelatedImage,
  productRelatedImageAlt,
  classes,
  bg,
  shopBtnShow = false,
  btn,
  link,
  fontColor,
  showLabels = false,
  isLazyLoading,
  alt,
  sku,
  preferred,
  productVariant,
}) => {
  const retailerClickHandler = useCallback(() => {
    const shopName = btn?.shopName || btn?.link?.[0]?.name;
    gtmService.emitRetailerClick({ title, sku, preferred, productVariant }, shopName);
  }, []);

  return (
    <div className={`card ${classes}`}>
      <Link
        className="nf-card-holder-link"
        to={link}
        style={
          bg &&
          ({
            backgroundColor: `var(--${bg})`,
          } as React.CSSProperties)
        }
      >
        {showLabels && label ? <CardLabel label={label} labelBgColor={labelBgColor} /> : null}
        {img?.gatsbyImage && (
          <UniversalImage
            img={img}
            imageAlt={alt}
            wrapperClassName="nf-card__img type--img"
            imgStyle={{ objectFit: 'contain' }}
            objectFitData={{ objectFit: 'contain' }}
            withDirectionClassName
            isLazyLoading={isLazyLoading}
          />
        )}
        <div className="card-body">
          <div
            className="card-title"
            title={title}
            style={fontColor && ({ color: `var(--${fontColor})` } as React.CSSProperties)}
          >
            <ProductCardTitle title={title} />
          </div>

          <DangerouslySetInnerHTML
            style={fontColor && ({ color: `var(--${fontColor})` } as React.CSSProperties)}
            className="card-text"
            html={text}
          />
        </div>
        {productRelatedImage?.gatsbyImage ? (
          <UniversalImage
            img={productRelatedImage}
            imageAlt={productRelatedImageAlt}
            wrapperClassName="nf-card__character-img"
            imgStyle={{ objectFit: 'contain' }}
            objectFitData={{ objectFit: 'contain', objectPosition: '100% 100%' }}
          />
        ) : productRelatedImage?.fallbackUrl ? (
          <UniversalImage
            img={productRelatedImage.fallbackUrl}
            imageAlt={productRelatedImageAlt}
            wrapperClassName="nf-card__character-img"
            imgStyle={{ objectFit: 'contain' }}
            objectFitData={{ objectFit: 'contain', objectPosition: '100% 100%' }}
          />
        ) : null}
      </Link>
      {btn?.link?.[0]?.url && btn?.link?.[0]?.name ? (
        <ProductLink
          isHidden={!shopBtnShow}
          productLink={btn}
          retailerClickHandler={retailerClickHandler}
        />
      ) : null}
    </div>
  );
};

/**
 *  ATTENTION! Request should mirror /utils/algolia/productsList.js
 */
export const query = graphql`
  fragment FragmentProductCard on IProductCard {
    sku
    link
    lang
    preferred {
      title
      id
    }
    descriptionLong
    descriptionShort
    descriptionExtended
    descriptionOverTheTitle
    footnotes
    defaultProductTitle
    defaultProductImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxHeight: 150, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    productImageAlt
    productRelatedImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 256, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    productRelatedImageAlt
    productLinks {
      properties {
        ...FragmentProductLink
      }
    }
    productFamily {
      ...FragmentProductFamily
    }
    seoMetaDescription
    pageName
    productVariant
  }
`;

export default ProductCard;
